import React from "react"
import { partnerPerksData } from "@util/whelpsData"
import "react-multi-carousel/lib/styles.css"
import "./DragonPass.scss"

export default function DragonPass() {

  const PerksInfoItem = (props) => {
    const perks = props.data.perks

    return (
      <div className="mt-6 grid-item md:mt-0 text-center">
        <div className="perks-card" style={{ borderRadius: props.data.backtype == 1 ? "30px 0px 30px 0px " : "0px 30px 0px 30px" }}>
          <div className="perks-card-content" style={{ borderRadius: props.data.backtype == 1 ? "29px 0px 29px 0px " : "0px 29px 0px 29px" }}>
            <div className="text-2xl tracking-wider font-lemon text-primary-whelps md:text-4xl">
              {props.data.title}
            </div>
            <img src="/dragonpass/line.svg" className="mt-3 divider" />
            <div className="card-content mx-auto mt-6 text-lg leading-8 font-roboto-light max-w-xl text-light-whelps text-left">
              {props.data.content}
              <ul className="mt-4 ">
              {perks.map((perk,idx)=>(
                <li key={idx}>* {perk}</li>
              ))}
              </ul>
            </div>
            <div className="justify-center flex mt-3">
              {props.data.twitter && <a href={props.data.twitter}><img src="/dragonpass/icon_twitter.png" className="mx-1 w-10 h-10" /></a>}
              {props.data.discord && <a href={props.data.discord}><img src="/dragonpass/icon_discord.png" className="mx-1 w-10 h-10" /></a>}
              {props.data.website && <a href={props.data.website}><img src="/dragonpass/icon_web.png" className="mx-1 w-10 h-10" /></a>}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div>
        <div className="py-8 md:py-20 bg-secondary-whelps second-part" >
          <div className="container mx-auto">
            <div className="grid grid-cols-1 gap-20 mt-12 lg:grid-cols-2 md:grid-cols-1 lg:gap-10">
              <div className="grid-item mt-6 md:mt-0 " >
                <div className="video-card mr-3">
                  <img src="/dragonpass/exclu.svg" className="mark relative" />
                  <video controls
                    style={{ width: "100%", height: "300px" }}
                    className="w-full mx-auto rounded-xl">
                    <source src="/movie/perks.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div className=" grid-item mt-6 md:mt-0">
                <div>
                  <div className="info-card">
                    <img src="/dragonpass/line37.svg" className="border-line-left" />
                    <div className="info-content">
                      <div className="text-2xl tracking-wider font-lemon text-primary-whelps md:text-4xl">
                        What is the DragonPass?
                      </div>
                      <div className="card-content mx-auto mt-6 text-lg leading-8 font-roboto-light tmax-w-xl text-light-whelps">
                      The DRAGONPASS will be your on-chain record of all tokens earned and spent, your ticket to exclusive holder benefits, giveaways and more! It will even have cross-chain capabilities. The DragonPass will also play a factor in the upcoming WhelpS: SlipStream P2E game, stay tuned for more details!
                      </div>
                    </div>
                    <img src="/dragonpass/line37.svg" className="border-line-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mx-auto perks-list">
            <div className="bg-primary-whelps font-bold font-lemon py-3 text-2xl md:text-4xl text-center text-white w-full partner-title">
              Partner Perks
            </div>
            <div className="grid grid-cols-1 gap-5 mt-16 lg:grid-cols-2 md:grid-cols-1 lg:gap-10 pb-6">
              {partnerPerksData.map((data, idx) => (
                <PerksInfoItem data={data} key={idx} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
