import React from "react"
import Layout from "@components/layout/Layout/Layout"
import DragonPass from "@components/partials/DragonPass"

const DragonPassPage = () => (
  <Layout
    title="DragonPass || WhelpS NFT"
    contentClassName="px-0 max-w-full bg-secondary-whelps"
    page="dragonpass"
  >
    <DragonPass />
  </Layout>
);

export default DragonPassPage
